import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        countryList(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/address/country-list')
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        statesList(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/address/states-list')
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
