import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/member/member-list', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/member/member-get?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/member/member-save', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/member/member-save', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/member/member-delete?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        paymentCancel(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/payment/subscription-cancel?id=${id}`, {})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        approveItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/member/approve?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchTransactions(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/payment/member-payments', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeMemberList(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/member/delete-selected', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
