<template>
  <b-card>
    <b-tabs pills content-class="border-top">
      <b-tab active>
        <template #title>
          <feather-icon icon="BookIcon"/>
          <span>General</span>
        </template>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form @submit.prevent="handleSubmit(onSubmit)" enctype="multipart/form-data">
            <b-row class="mt-1">
              <b-col cols="12" md="6" lg="6">
                <b-row>
                  <b-col cols="12">
                    <validation-provider #default="validationContext" name="Name" rules="required">
                      <b-form-group label="Name">
                        <b-form-input trim placeholder="Name" v-model="dataInfo.name" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <validation-provider #default="validationContext" name="LastName" rules="required">
                      <b-form-group label="LastName">
                        <b-form-input trim placeholder="LastName" v-model="dataInfo.lastname" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <validation-provider #default="validationContext" name="Phone">
                      <b-form-group label="Phone">
                        <b-form-input trim placeholder="Phone" v-model="dataInfo.phone" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <validation-provider #default="validationContext" name="E-Mail" rules="required|email">
                      <b-form-group label="E-Mail">
                        <b-form-input trim placeholder="E-Mail" v-model="dataInfo.email" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="12" lg="12">
                    <validation-provider #default="validationContext" name="Gender" rules="required">
                      <b-form-group label="Gender" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.gender" :options="genderOptions" :reduce="val => val.value" :clearable="false"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <validation-provider #default="validationContext" name="Yearly Fee Amount" rules="required">
                      <b-form-group label="Yearly Fee Amount">
                        <b-form-input trim placeholder="Yearly Fee Amount" v-model="dataInfo.yearly_fee_amount" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <validation-provider #default="validationContext" name="Monthly Fee Amount" rules="required">
                      <b-form-group label="Monthly Fee Amount">
                        <b-form-input trim placeholder="Monthly Fee Amount" v-model="dataInfo.monthly_fee_amount" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <validation-provider #default="validationContext" name="Status" rules="required">
                      <b-form-group label="Status" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.status" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <validation-provider #default="validationContext" name="Craft">
                      <b-form-group label="Craft">
                        <b-form-input trim placeholder="Craft" v-model="dataInfo.craft" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <validation-provider #default="validationContext" name="Member Type" rules="required">
                      <b-form-group label="Member Type" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.member_type" :options="memberTypeOptions" :reduce="val => val.value" :clearable="false"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-col cols="12" md="12" lg="12">
                  <validation-provider #default="validationContext" name="Region" rules="required">
                    <b-form-group label="Region" :state="getValidationState(validationContext)">
                      <v-select v-model="dataInfo.states_id" :options="statesOptions" :reduce="val => val.value" :clearable="false"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="12" lg="12">
                  <validation-provider #default="validationContext" name="Street Address">
                    <b-form-group label="Street Address" :state="getValidationState(validationContext)">
                      <b-form-input trim placeholder="Street Address" v-model="dataInfo.street_address" :state="getValidationState(validationContext)"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="12" lg="12">
                  <validation-provider #default="validationContext" name="Address Line 2">
                    <b-form-group label="Address Line 2" :state="getValidationState(validationContext)">
                      <b-form-input trim placeholder="Address Line 2" v-model="dataInfo.address" :state="getValidationState(validationContext)"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="12" lg="12">
                  <validation-provider #default="validationContext" name="City">
                    <b-form-group label="City" :state="getValidationState(validationContext)">
                      <b-form-input trim placeholder="City" v-model="dataInfo.city_name" :state="getValidationState(validationContext)"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="12" lg="12">
                  <validation-provider #default="validationContext" name="State / Province / Region">
                    <b-form-group label="State / Province / Region" :state="getValidationState(validationContext)">
                      <b-form-input trim placeholder="State / Province / Region" v-model="dataInfo.region_name" :state="getValidationState(validationContext)"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="12" lg="12">
                  <validation-provider #default="validationContext" name="ZIP / Postal Code">
                    <b-form-group label="ZIP / Postal Code" :state="getValidationState(validationContext)">
                      <b-form-input trim placeholder="ZIP / Postal Code" v-model="dataInfo.zip_code" :state="getValidationState(validationContext)"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="12" lg="12">
                  <validation-provider #default="validationContext" name="Country" rules="required">
                    <b-form-group label="Country" :state="getValidationState(validationContext)">
                      <v-select v-model="dataInfo.country_id" :options="countryOptions" :reduce="val => val.value" :clearable="false"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="12" lg="12">
                  <validation-provider #default="validationContext" name="IU Number">
                    <b-form-group label="IU Number">
                      <b-form-input trim placeholder="IU Number" v-model="dataInfo.iu_number" :state="getValidationState(validationContext)"/>
                      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <div class="d-flex justify-content-center align-content-end">
                  <b-avatar
                      ref="previewEl"
                      :src="(dataInfo.profilePictureBase64 ? dataInfo.profilePictureBase64 : getApiFile(dataInfo.image_url))"
                      :text="avatarText(dataInfo.name + ' ' + dataInfo.lastName)"
                      button
                      variant="primary"
                      size="70px"
                      @click="$refs.refInputEl.click()"/>
                  <input
                      ref="refInputEl"
                      type="file"
                      class="d-none"
                      @input="inputImageRenderer">
                </div>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <validation-provider #default="validationContext" name="Experience Text">
                  <b-form-group label="Experience Text" :state="getValidationState(validationContext)">
                    <b-form-textarea trim placeholder="Please list any experience with bricklaying or craftswork" v-model="dataInfo.experience_text" :state="getValidationState(validationContext)" rows="4"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <validation-provider #default="validationContext" name="Work history">
                  <b-form-group label="Work history" :state="getValidationState(validationContext)">
                    <b-form-textarea trim placeholder="Work history" v-model="dataInfo.work_history_text" :state="getValidationState(validationContext)" rows="4"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Level of education">
                  <b-form-group label="Level of education" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.education_level" :options="educationLevelOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Will you submit to drug test">
                  <b-form-group label="Will you submit to drug test" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.drug_test" :options="yesNoOptionsNum" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Valid drivers license">
                  <b-form-group label="Valid drivers license" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.valid_driver_licence" :options="yesNoOptionsNum" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <action-buttons :back-route="'member-list'"/>
          </b-form>
        </validation-observer>
      </b-tab>
      <TransactionInfo :member-data="dataInfo"></TransactionInfo>
    </b-tabs>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BAvatar, BTabs, BTab, BFormTextarea,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {required, email} from '@validations'
import {onUnmounted, ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/member/store"
import addressModule from "@/store/common/address"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import {avatarText, getApiFile, genderOptions, yesNoOptionsNum} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue"
import ActionButtons from "@/components/Form/ActionButtons.vue"
import {toastMessage} from "@core/utils/utils"
import {useInputImageRenderer} from "@core/comp-functions/forms/form-utils"
import PaymentInfo from "@/views/member/payment/Index.vue";
import TransactionInfo from "@/views/member/transaction/List.vue";

export default {
  components: {
    BFormTextarea,
    BTab,
    BTabs,
    BAvatar,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    vSelect,
    ActionButtons,
    Overlay,

    ValidationProvider,
    ValidationObserver,
    PaymentInfo,
    TransactionInfo,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_ADDRESS_MODULE_NAME = 'store-address'
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_ADDRESS_MODULE_NAME, addressModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_ADDRESS_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)

    const dataInfo = ref({
      id: 0,
      name: '',
      lastname: '',
      email: '',
      phone: '',
      title: '',
      image_url: '',
      profilePictureBase64: null,
      gender: 0,
      states_id: null,
      yearly_fee_amount: null,
      monthly_fee_amount: null,
      status: 1,
      street_address: '',
      address: '',
      city_name: '',
      region_name: '',
      zip_code: '',
      country_id: null,
      work_history_text: '',
      experience_text: '',
      education_level: null,
      drug_test: null,
      valid_driver_licence: null,
      iu_number: '',
      craft: '',
      member_type: 1
    })

    const statesOptions = ref([])
    const countryOptions = ref([])

    const previewEl = ref(null)
    const refInputEl = ref(null)
    const {inputImageRenderer} = useInputImageRenderer(refInputEl, base64 => {
      dataInfo.value.profilePictureBase64 = base64
    })

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'member-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.error_message)
      }).finally(message => {
        busy.value = false
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id > 0 ? router.currentRoute.params.id : 0}).then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.error_message)
        if (error.response.status === 403) {
          router.push({name: 'member-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    busy.value = true
    store.dispatch('store-address/statesList').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.status === 1) {
          statesOptions.value.push({label: value.name, value: value.id})
        }
      })
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.error_message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-address/countryList').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.status === 1) {
          countryOptions.value.push({label: value.name, value: value.id})
        }
      })
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.error_message)
    }).finally(message => {
      busy.value = false
    })

    const educationLevelOptions = [
      {label: 'GED/High School Diploma', value: 1},
      {label: 'Apprenticeship', value: 2},
      {label: 'Some college', value: 3},
      {label: 'College degree', value: 4},
    ]

    const statusOptions = [
      {label: 'Passive', value: 0},
      {label: 'Active', value: 1},
      {label: 'InActive', value: 2},
    ]

    const memberTypeOptions = [
      {label: 'None', value: 0},
      {label: 'New', value: 1},
      {label: 'Existing', value: 2},
    ]

    return {
      busy,
      dataInfo,
      previewEl,
      refInputEl,
      refFormObserver,
      statesOptions,
      countryOptions,
      educationLevelOptions,
      statusOptions,
      memberTypeOptions,

      genderOptions,
      yesNoOptionsNum,

      onSubmit,
      getValidationState,
      resetForm,
      getApiFile,
      avatarText,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
