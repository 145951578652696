<template>
  <b-tab v-if="$router.currentRoute.params.id > 0">
    <template #title>
      <feather-icon icon="UsersIcon"/>
      <span>Subscription</span>
    </template>
    <b-card no-body class="border mt-1 mb-0">
      <div class="m-2">
        <div v-if="memberData.square_subscription_id === null">
          <div id="card-container"></div>
          <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              id="card-button"
              type="button"
              @click="handlePaymentMethodSubmission">
            <feather-icon icon="SaveIcon" size="16"/>
            <span class="align-middle"> Subscript</span>
          </b-button>
          <div id="payment-status-container" :class="statusClass" v-if="paymentStatusVisible">Please waiting...</div>
        </div>
        <div v-if="memberData.square_subscription_id != null">
          <div>
            <p>Subscription Status:
              <b-badge variant="success">{{ memberData.subscription_status }}</b-badge>
            </p>
          </div>
          <div class="border-top border-bottom pt-1 d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-between align-items-center">
              <div class="pr-3">
                <p>Monthly Payments </p>
                <p><strong>${{ memberData.subscription_amount }}</strong> <small>per Month</small></p>
              </div>
              <div>
                <p>Active Since</p>
                <p><strong>{{ memberData.subscription_date }}</strong></p>
              </div>
            </div>
            <div>
              <b-button variant="flat-secondary" @click="cancelPayment">Cancel Subscript</b-button>
            </div>
          </div>
          <div class="pt-1">
            <p>Payment Methods</p>
            <div class="border p-1">
              <div class="media">
                <div class="media-aside align-self-start">
                    <span class="b-avatar badge-light-primary rounded">
                      <span class="b-avatar-custom">
                        <feather-icon icon="BookIcon"/>
                      </span>
                    </span>
                </div>
                <div class="media-body">
                  <h6>{{ memberData.subscription_card_type }} **** {{ memberData.subscription_card_number }}</h6>
                  <h6>Expires {{ memberData.subscription_card_expires }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </b-tab>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BPagination,
  BTooltip,
  BTab,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  BLink, BBadge
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Overlay from "@/components/Overlay.vue"
import {ActiveColumnTable, DateColumnTable, FooterTable, BooleanColumnTable} from "@/components/Table";
import router from "@/router";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from "@/store";
import Vue from "vue"

export default {
  name: "PaymentInfo",
  components: {
    BBadge,
    BLink,
    BButton,
    BButtonGroup,
    BButtonToolbar,
    BCard, BRow, BCol, BFormInput, BTable, BPagination, BTooltip, BTab,

    Overlay, FooterTable, DateColumnTable, ActiveColumnTable, BooleanColumnTable,

    vSelect,
  },
  data() {
    return {
      appId: 'sq0idp-Z6brT9M0JXrSjB103Que3g',
      locationId: '0H0H458EZTDRD',
      payments: null,
      card: null,
      paymentStatus: null,
    };
  },
  mounted() {
    this.loadSquareJS();
  },
  methods: {
    loadSquareJS() {
      const script = document.createElement('script');
      script.src = 'https://web.squarecdn.com/v1/square.js';
      script.onload = () => {
        this.initializeSquare();
      };
      document.head.appendChild(script);
    },
    async initializeSquare() {
      if (!window.Square) {
        throw new Error('Square.js failed to load properly');
      }

      try {
        this.payments = window.Square.payments(this.appId, this.locationId);
      } catch {
        this.displayPaymentResults('FAILURE');
        return;
      }

      try {
        this.card = await this.initializeCard();
      } catch (e) {
        console.error('Card initialization failed', e);
      }
    },
    initializeCard() {
      return new Promise((resolve, reject) => {
        this.payments.card().then(card => {
          card.attach('#card-container');
          resolve(card);
        }).catch(error => {
          reject(error);
        });
      });
    },
    async handlePaymentMethodSubmission() {
      try {
        this.displayPaymentResults('FAILURE');

        const cardButton = document.getElementById('card-button');
        cardButton.disabled = true;

        const token = await this.tokenize(this.card);

        this.$http.post('/payment/subscription', {
          member_id: router.currentRoute.params.id,
          card_source_id: token
        }).then(res => {
          this.displayPaymentResults('');

          if (res.data.status === 'success') {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: res.data.error_message,
              },
            })

            location.reload()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: res.data.error_message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        }).catch(error => {
          this.displayPaymentResults('');
          console.log(error.response)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: error.response !== undefined ? error.response.data.error_message : 'Error occurred during login!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })

      } catch (e) {
        console.error(e.message);
        this.displayPaymentResults('FAILURE');
      } finally {
        const cardButton = document.getElementById('card-button');
        cardButton.disabled = false;
      }
    },
    tokenize(paymentMethod) {
      return new Promise((resolve, reject) => {
        paymentMethod.tokenize().then(tokenResult => {
          if (tokenResult.status === 'OK') {
            resolve(tokenResult.token);
          } else {
            let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
            if (tokenResult.errors) {
              errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
            }
            reject(new Error(errorMessage));
          }
        }).catch(error => {
          reject(error);
        });
      });
    },
    displayPaymentResults(status) {
      this.paymentStatus = status;
    },
  },
  computed: {
    statusClass() {
      if (this.paymentStatus === 'SUCCESS') {
        return 'is-success';
      } else if (this.paymentStatus === 'FAILURE') {
        return 'is-failure';
      } else {
        return '';
      }
    },
    paymentStatusVisible() {
      return this.paymentStatus === 'SUCCESS' || this.paymentStatus === 'FAILURE';
    },
    paymentStatusMessage() {
      if (this.paymentStatus === 'SUCCESS') {
        return 'Subscription Successful!';
      } else if (this.paymentStatus === 'FAILURE') {
        return 'Subscription Failed!';
      } else {
        return '';
      }
    },
  },
  props: {
    memberData: {
      type: Object,
      default: () => {
      }
    },
  },
  setup(props) {
    const cancelPayment = () => {

      Vue.swal({
        title: 'Are you sure?',
        text: 'Cancel subscription',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Cancel',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/paymentCancel', {id: router.currentRoute.params.id}).then(response => {
            location.reload()
          }).catch(error => {

          }).finally(message => {

          })
        }
      })


    }

    return {
      cancelPayment
    }
  }
}
</script>

