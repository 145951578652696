<template>
  <b-tab v-if="$router.currentRoute.params.id > 0 && isVisible">
    <template #title>
      <feather-icon icon="PackageIcon"/>
      <span>Payments</span>
    </template>

    <b-card no-body class="border mt-1 mb-0">
      <div class="m-1">
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <v-select v-model="perPage" class="per-page-selector d-inline-block mx-50" :options="perPageOptions" :clearable="false"/>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button-toolbar justify>
                <b-button-group>
                  <b-button @click="reFetchData" variant="outline-primary" size="md">
                    <feather-icon icon="RefreshCcwIcon" size="18"/>
                    <span class="align-middle"></span>
                  </b-button>
                </b-button-group>
              </b-button-toolbar>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
          ref="refListTable"
          primary-key="id"
          empty-text="No record found"
          :class="`position-relative table-sticky ${totalCount > 0 && totalCount < 5 ? 'dropdown-h150': ''}`"
          :items="fetchList"
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          responsive striped bordered hover show-empty>

        <template #cell(id)="data">
          <b-link class="font-weight-bold">#{{ data.value }}</b-link>
        </template>

        <template #cell(created_date)="data">
          <date-column-table :data="data.value"></date-column-table>
        </template>

        <template #cell(amount)="data">
          <div class="text-nowrap">{{ data.value }}</div>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <footer-table :data-meta="dataMeta"></footer-table>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" v-model="currentPage" :total-rows="totalCount" :per-page="perPage" first-number last-number>

              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>

              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>

            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <Overlay :busy="busy"></Overlay>
    </b-card>
  </b-tab>
</template>

<script>
import {BCard, BRow, BCol, BFormInput, BTable, BPagination, BTooltip, BTab, BButtonToolbar, BButtonGroup, BButton, BLink, BBadge, BDropdown, BDropdownItem, BMedia, BAvatar} from 'bootstrap-vue'
import {FooterTable, DateTimeColumnTable, EditButtonTable} from '@/components/Table'
import vSelect from 'vue-select'
import Overlay from "@/components/Overlay.vue"
import useList from './useList'
import BooleanColumnTable from "@/components/Table/BooleanColumnTable.vue"
import DateColumnTable from "@/components/Table/DateColumnTable.vue"
import ActiveColumnTable from "@/components/Table/ActiveColumnTable.vue"
import {getApiFile, avatarText} from "@core/utils/filter"

export default {
  name: "TransactionList",
  components: {
    EditButtonTable,
    BAvatar, BMedia,
    BDropdownItem,
    BDropdown,
    BBadge,
    BLink,
    BButton,
    BButtonGroup,
    BButtonToolbar,
    BCard, BRow, BCol, BFormInput, BTable, BPagination, BTooltip, BTab,
    ActiveColumnTable,
    DateColumnTable,
    BooleanColumnTable,
    Overlay, FooterTable, DateTimeColumnTable, vSelect
  },
  setup() {

    const {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,
      isVisible,

      fetchList,
      reFetchData,
    } = useList()

    return {
      busy,
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      isVisible,

      fetchList,
      reFetchData,

      getApiFile,
      avatarText
    }
  }
}
</script>